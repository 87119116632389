.AboutPageBackground{
    display: block;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #5e4b62;
    color: aliceblue;
    min-height: 70vh;
    padding-bottom: 60px;
    width: 80%;
    margin-left: 10vw;
    filter:drop-shadow(0px 4px 4px #28152c);
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.inlineLink{
    text-decoration: underline;
    color:aliceblue
}


/* body {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
} */
 
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
