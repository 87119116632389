.Homepage{
  display: block;
  font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: #5e4b62;
  min-height: 70vh;
  padding-bottom: 60px;
  width: 80%;
  margin-left: 10vw;
  filter:drop-shadow(0px 4px 4px #28152c);
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}


/* About Summary CSS */
@media screen and (max-width: 10920px){
  .AboutSummaryGrid{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 500px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  .AboutSummaryGrid div{
    /* padding: 50px; */
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 70px;
    margin-left: 42%;
    text-align: left;
    /* margin-top: 10vh; */
    color: azure;
  }

  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 32vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -70px;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }
  .TitleLink:hover{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    /* font-size: 3.3vh; */
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    /* width: 32vw; */
    /* height: 3.8vh; */
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    /* margin-left: 30.15%; */
    /* margin-top: -9.15vh; */
    filter:drop-shadow(1px 1px 1px #28152c);
  }

  .AboutTextComponent{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: azure;
    /* position: absolute; */
    margin-left: 42%;
    margin-top: 310px;
    text-align: left;
    /* padding-right: 20%; */
  }

  .HeadshotImage{
    /* background-color: #28152c; */
    background-image: url("../images/headshot_edited.png");
    background-size: 18vw;
    background-position: center;
    background-repeat: no-repeat;
    /* position: absolute; */
    margin-left: 20%;
    margin-top: -3%;
    max-height: 110%;
    max-width: 21%;
    /* top: 5vh;
    bottom: 0px;
    left: 12.5vw;
    right: 0vw;   */
  }
}
@media screen and (max-width: 1510px) {
  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 60px;
    margin-left: 42%;
    text-align: left;
    /* margin-top: 10vh; */
    color: azure;
  }

  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 32vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -6%;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }
  .TitleLink:hover{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    /* font-size: 3.3vh; */
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    /* width: 32vw; */
    /* height: 3.8vh; */
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    /* margin-left: 30.15%; */
    /* margin-top: -9.15vh; */
    filter:drop-shadow(1px 1px 1px #28152c);
  }

  .AboutTextComponent{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: azure;
    /* position: absolute; */
    margin-left: 42%;
    margin-top: 20%;
    text-align: left;
    /* padding-right: 20%; */
  }
}

@media screen and (max-width: 1500px) {
  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 40px;
    margin-left: 42%;
    text-align: left;
    margin-top: 120px;
    color: azure;
  }

  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 32vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -40px;
    margin-bottom: auto;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }
  .TitleLink:hover{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    /* font-size: 3.3vh; */
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    /* width: 32vw; */
    /* height: 3.8vh; */
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    /* margin-left: 30.15%; */
    /* margin-top: -9.15vh; */
    filter:drop-shadow(1px 1px 1px #28152c);
  }

  .AboutTextComponent{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: azure;
    /* position: absolute; */
    margin-left: 42%;
    margin-top: 250px;
    margin-bottom:auto;
    text-align: left;
    /* padding-right: 20%; */
  }
  .HeadshotImage{
    /* max-width: 21%; */
    background-size: 100%;
    max-height: 100%;
    /* max-width: 20%; */
    /* margin-left: 16%; */
    /* margin-left: auto; */
    margin-top: -6%;
  }
}

@media screen and (max-width: 800px) {
  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 30px;
    margin-left: 42%;
    text-align: left;
    margin-top: 150px;
    color: azure;
  }

  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 32vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -30px;
    margin-bottom: auto;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }
  .TitleLink:hover{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    /* font-size: 3.3vh; */
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    /* width: 32vw; */
    /* height: 3.8vh; */
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    /* margin-left: 30.15%; */
    /* margin-top: -9.15vh; */
    filter:drop-shadow(1px 1px 1px #28152c);
  }

  .AboutTextComponent{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: azure;
    /* position: absolute; */
    margin-left: 42%;
    margin-top: 250px;
    margin-bottom:auto;
    text-align: left;
    /* padding-right: 20%; */
  }
}

@media screen and (max-width: 600px) {
  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 25px;
    margin-left: 42%;
    text-align: left;
    /* margin-top: 160px; */
    margin-top: 15vh;
    color: azure;
  }

  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 32vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -20px;
    margin-bottom: auto;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }

  .HeadshotImage{
    /* background-color: #28152c; */
    background-image: url("../images/headshot_edited.png");
    background-size: 27vw;
    background-position: center;
    background-repeat: no-repeat;
    /* position: absolute; */
    margin-top: 15%;
    margin-bottom: auto;
    margin-left: 5%;
    height: 350px;
    max-width: 35%;
    /* top: 5vh;
    bottom: 0px;
    left: 12.5vw;
    right: 0vw;   */
  }
}

@media screen and (max-width: 475px) {
  .TitleText{
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 22px;
    margin-left: 42%;
    text-align: left;
    margin-top: 160px;
    color: azure;
  }
  .TitleLink{
    order:2;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* line-height: 3.8vh; */
    font-size: 3.3vh;
    color: azure;
    display: block;
    /* background-color: #922FA8; */
    background-color: #8f509d;
    width: 38vw;
    height: 3.8vh;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
    text-align: left;
    padding-left: 12vw;
    margin-left: 30%;
    margin-top: -20px;
    margin-bottom: auto;
    filter:drop-shadow(3px 3px 3px #28152c);
    transition:all 300ms
  }
}

/* Project Summary CSS */
.ProjectLinkBackground{
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  background-color: #312f31;
  border-style: solid;
  border-color: #676367;
  border-width: 2px;
  border-radius: 2vh;
  width: 270px;
  max-width: 95%;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  filter: drop-shadow(1px 1px 2px #050205);
  overflow: hidden;
  transition:
    margin-top 300ms,
    margin-left 300ms,
    filter 200ms,
    background-color 600ms;
}

.ProjectLinkBackground:hover{
  filter: drop-shadow(0px 0px 2px #050205);
  background-color: rgb(52, 50, 52);
}

.ProjectImage{
  max-width: 30%;
  max-height: 80%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.ProjectTextContainer{
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.ProjectTitle{
  color: #8f509d;
  /* padding-left: 0vw; */
  font-size: large;
}

.ProjectDesc{
  color:aliceblue;
  padding-left: 0vw;
  font-size: small;
  margin-top: -1.5vh;
}

.ProjectSummaryBackground{
  display: flex;
  width: 100%;
  height: fit-content;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  /* padding-top: 0%; */
  background-color: #28152c;
}

.ProjectLinkContainer{
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: wrap;
}

.ProjectSummaryTitle{
  color: aliceblue;
  font-size: 300%;
  text-decoration: none;
}

.ProjectLinkHolder{
  padding-top: 3px;
  padding-bottom: 3px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
