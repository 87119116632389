.PageContainer{
    width: 100%;
    height: 100%;
}

.ProjectsPageBackground{
    display: block;
    font-family: 'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #5e4b62;
    color: aliceblue;
    min-height: 70vh;
    padding-bottom: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    filter:drop-shadow(0px 4px 4px #28152c);
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.ProjectGrid{
    display: flex;
    flex-flow: wrap;
    /* outline-style: solid; */
}

.GridComponent{
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100%;
    /* outline-style: solid; */

}