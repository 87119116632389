.App {
  text-align: center;
}

.testButton{
  color:#61dafb
}

.header{
  /* height: 16.5vh; */
  width: 100%;
  background: rgb(50,45,51);
  /* background: radial-gradient(circle, rgba(50,45,51,1) 0%, rgba(62,45,66,1) 99%); */
  background: radial-gradient(circle, #39333b 0%, #312f31 99%);
  text-align: center;
  padding-top: 3vh;
  padding-bottom: 10px;
  display: grid;
  row-gap: 1vh;
}

.logo{
  font-size: 280%;
  font-family:'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:white;
  text-decoration: none;
  width:fit-content;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.logo:hover span{
  display: none;
  /* font-size: 6vh;  
  font-family:'Lucida Console', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:white;
  text-decoration: none; */
  /* animation: blink 1s linear infinite; */
}

.logo:hover::before{
  content: ":/Jacob>_";
  animation: blink 1s linear infinite;
}

@keyframes blink{
  0%{
    content: ":/Jacob>_";
  }
  100%{
    content: ":/Jacob>";
  }
}
@media screen and (max-width: 10920px) {
  .navBar{
    width:80%;
    /* border-style: solid; */
    text-align: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 3vh; */
    margin-bottom: 0vh;
    overflow: hidden;
  }
  
  .navLinkButton {
    display: block;
    width: 15%;
    height: 40%;
    /* background: #453152; */
    background: #5e4b62;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    line-height: 2vh;
    margin-left:auto;
    margin-right: auto;
    float: left;
    transition:
      background 300ms,
      width 300ms,
      height 300ms;
  }
}


@media  screen and (max-width: 800px) {
  .navLinkButton{
    font-size: 14px;
  }
}

.navLinkButton:hover {
  display: block;
  width: 17%;
  height: 60%;
  background: #5e4b62;
  /* background: #604572; */
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  line-height: 2vh;
  margin-left:auto;
  margin-right: auto;
  float: left;
}

.AppPage{
  align-content: center;
  text-align: center;
  /* background-color: #322D33; */
  background-color: rgba(62,45,66,1);
  /* background: radial-gradient(circle, rgba(50,45,51,1) 0%, rgba(62,45,66,1) 99%); */
  background: radial-gradient(circle, #39333b 0%, #312f31 99%);
  width: 100%;
  padding-bottom: 8%;
  min-height: 70vh;
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
